import { Form as FormikForm } from 'formik';
import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import Modal from 'components/Modal';

export const ButtonsContainer = styled.div`
  display: flex;

  & > :first-child {
    margin-right: 25px;
  }
`;

export const SendLoanProgramContainer = styled(Modal)`
  padding: 30px;
  min-width: 530px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    width: 95%;
  }
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  & > :not(:last-child) {
    margin-bottom: 30px;
  }
`;
